import { z } from 'zod'
import { getPersonIds } from '../utils'
import type { DefaultSession } from '~/next-auth'
import { canUse } from '~/authorization/permissions'

export const MAX_FILE_SIZE_MB = 5
export const DATA_FOLDER = process.env.FILESTORAGE_PATH
export const POSTAL_FOLDER = process.env.POSTAL_STORAGE_PATH
export const TEMP_FOLDER = `${DATA_FOLDER}/temp`
export const IMPORT_FOLDER = `${TEMP_FOLDER}/imports`
export const TEMPLATE_FOLDER = `${DATA_FOLDER}/templates`

export const fileSizeToMB = (size: number) => size / (1024 * 1024)
export const isFileSizeOverLimit = (file: File) => fileSizeToMB(file.size) > MAX_FILE_SIZE_MB

// See https://gist.github.com/hiaux0/a26b964f69955d7d3c2907b1e48788f7 for more options
export const FILE_TYPES_TO_MIME = {
  '.txt': 'text/plain',
  '.pdf': 'application/pdf',
  '.doc': 'application/msword',
  '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.png': 'image/png',
  '.jpeg': 'image/jpeg',
  '.csv': 'text/csv',
}
export type FileExtensions = keyof typeof FILE_TYPES_TO_MIME

export const fileSchema = z.instanceof(File)
  .refine(({ type }) => Object.values(FILE_TYPES_TO_MIME).includes(type), 'INVALID_TYPE')
  .refine(file => !isFileSizeOverLimit(file), 'INVALID_SIZE')

export function canUseFile(session: DefaultSession, documentUserId?: string | null) {
  if (canUse(session.user.role, 'documents.create.others')) {
    return true
  }
  if (!documentUserId) {
    console.error('NO USER ID')
    return false
  }
  return getPersonIds(session).includes(documentUserId)
}

export const generateFileName = (fileType: string) => `${Date.now()}.${fileType}`
